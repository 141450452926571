import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

export default (params) => ({
    slider: null,
    images: params.images,
    projectId: params.projectId,
    allImagesLoaded: false,
    init() {
        this.$nextTick(() => this.initSlider())

        window.addEventListener('search-updated-layout', event => {
            this.$nextTick(() => {
                if (this.slider === null) {
                    return
                }

                this.slider.mount()
            })
        })
    },

    initSlider() {
        this.appendImages(this.images, true)

        let glide = new Glide(this.$el.querySelector('.glide'), {
            gap: 0,
            perView: 1,
            autoplay: false,
            rewind: false,
        })

        glide.on('run.before', async ({ direction, steps }) => {

            if (this.allImagesLoaded) {
                return
            }

            await fetch(`/api/projects/${this.projectId}/slider-images`)
                .then((response) => response.json())
                .then((images) => {

                    this.removeLoadingElements()

                    this.allImagesLoaded = true

                    this.appendImages(images)

                    this.slider.update()
                })
        })

        this.slider = glide.mount()
    },

    appendImages(images = [], before = false) {

        let html = ''

        images.forEach((image) => {
            html += `<li class="glide__slide">${image}</li>`
        })

        this.$refs.glide.innerHTML = before ? 
            html + this.$refs.glide.innerHTML :
            this.$refs.glide.innerHTML + html
    },

    removeLoadingElements() {
        this.$el.querySelectorAll('.glide__slide--loading').forEach(e => e.remove())
    },

    destroy() {
        if (this.slider === null) {
            return
        }

        this.slider.destroy()
        this.slider = null
    }
})