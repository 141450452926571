import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default (el, { expression }, { cleanup }) => {
    const instance = tippy(el, { content: expression })

    cleanup(() => {
        instance.destroy()
    })
}
