import.meta.glob([
    '../images/**',
    '../fonts/**/*.{ttf,woff,woff2}',
]);

import './pwa';

import * as Sentry from "@sentry/browser"

if (window.sentryJavascriptDsn.length > 0) {
    Sentry.init({
        dsn: window.sentryJavascriptDsn,
        environment: window.environment,
        release: window.sentryRelease,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 0.1,
    })   
}

import './modules/CConsent'

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import Search from './modules/Search'
import Lightbox from './modules/Lightbox'
import Tooltip from './modules/Tooltip'
import Map from './modules/Map'
import CardImageSlider from './modules/CardImageSlider'
import ProjectImageSlider from './modules/ProjectImageSlider'
import StepButtons from './modules/StepButtons'
import { refreshImages } from './modules/LaravelMedialibrary'

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

Alpine.data('search', Search)
Alpine.data('map', Map)
Alpine.data('lightbox', Lightbox)
Alpine.directive('tooltip', Tooltip)
Alpine.data('cardImageSlider', CardImageSlider)
Alpine.data('projectImageSlider', ProjectImageSlider)
Alpine.data('stepButtons', StepButtons)

window.Alpine = Alpine

Livewire.start()

Livewire.hook('commit', ({ succeed }) => {
    succeed(() => {
        queueMicrotask(() => {
            // https://github.com/livewire/livewire/pull/7897
            queueMicrotask(() => {
                refreshImages()
            })
        })
    })
})