export default (params) => ({
    value: params.value,
    steps: params.steps,
    add() {
        let value = this.value || 0
        value = parseInt(value) + this.steps
        this.value = value
    },
    sub() {
        let value = this.value || 0

        if (value - this.steps <= 0) {
            this.value = null
            return
        }

        value = value - this.steps
        this.value = value
    },
})