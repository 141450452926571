import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css';
import { refreshImages } from './LaravelMedialibrary'

import '@glidejs/glide/dist/css/glide.theme.min.css';

export default (params) => ({
    slider: null,
    init() {
        let glide = new Glide(this.$refs.slider, {
            gap: 0,
            perView: 1,
            autoplay: false,
            rewind: false,
        })

        glide.on('run', (move) => {
            this.hideAllTitles()

            this.showTitle(glide.index)
        })

        glide.on('build.after', () => this.showTitle(0))

        this.slider = glide.mount()

        this.$nextTick(() => {
            this.slider.update()
            refreshImages()
        })
    },

    hideAllTitles() {
        const sliderLength = this.$el.querySelectorAll('.glide__slide').length

        for (let i = 0; i < sliderLength; i++) {
            let titleElm = this.$refs[`slider-item-title-${i}`]

            if (titleElm) {
                titleElm.style.opacity = 0
            }
        }
    },

    showTitle(index = 0) {
        let titleElm = this.$refs[`slider-item-title-${index}`]

        if (titleElm) {
            titleElm.style.opacity = 0.7
        }
    },

    goTo(index = 0)
    {
        this.slider.go(`=${index}`)
    },

    destroy() {
        if (this.slider === null) {
            return
        }

        this.slider.destroy()
        this.slider = null
    }
})