import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default (params) => ({
    show: false,
    showNextAndPrev: false,
    currentIndex: 0,
    items: [],
    atBeginning: true,
    atEnd: false,
    next() {
        this.currentIndex++
    },
    prev() {
        this.currentIndex--
    },
    init() {
        if (this.show) {
            document.body.classList.add('overflow-hidden')
        }

        this.$watch('items', () => {
            this.showNextAndPrev = this.items.length > 1
        })

        this.$watch('currentIndex', (index) => {
            if (index + 1 >= this.items.length) {
                this.atEnd = true
            } else {
                this.atEnd = false
            }

            if (index - 1 < 0) {
                this.atBeginning = true
            } else {
                this.atBeginning = false
            }
        })
    }
})