import L from 'leaflet'

L.Popup.include({
    _animateZoom(e) {
        if (this._map) {
            const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center),
                anchor = this._getAnchor();
            L.DomUtil.setPosition(this._container, pos.add(anchor));
        }
    },
});

import iconRetinaUrlIcon from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrlIcon from 'leaflet/dist/images/marker-icon.png'
import shadowUrlIcon from 'leaflet/dist/images/marker-shadow.png'

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetinaUrlIcon,
    iconUrl: iconUrlIcon,
    shadowUrl: shadowUrlIcon,
});

export default (options = {}) => ({

    mapElement: null,

    showLoader: true,

    poiMarkers: [],

    showMap() {
        this.showLoader = false

        this.$nextTick(() => this.initMap())
    },

    destroy() {
        if (this.mapElement === null) {
            return
        }

        this.mapElement.remove()
        this.mapElement = null
    },

    initMap() {
        if (this.mapElement) {
            console.warn('> Map was already inited')
            return
        }

        options = {
            zoom: 16,
            ...options
        }

        // Create the map
        this.mapElement = L.map(this.$el, {
            attributionControl: false,
            scrollWheelZoom: false,
        }).setView(options.center, options.zoom);

        // Set up the OSM layer
        L.tileLayer(
            import.meta.env.VITE_MAP_URL + '/styles/invest-berlin/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.maptiler.com/copyright/" rel="noopener" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" rel="noopener" target="_blank">&copy; OpenStreetMap contributors</a>',
            maxZoom: 18,
            crossOrigin: 'anonymous'
        }).addTo(this.mapElement);

        L.control.attribution({
            position: "bottomright",
            prefix: "",
        }).addTo(this.mapElement);

        // add a marker in the given location
        L.marker(options.center).addTo(this.mapElement);
    }
})